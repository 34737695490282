/* Import Poppins from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

.background {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
    url('../public/images/header_bg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  backdrop-filter: blur(100px);
  width: auto;
  height: 1300px;
}





.proof-image {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.7)),
    url('../public/images/header_bg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  backdrop-filter: blur(100px);
  width: auto;
  height: 900px;
}

@media only screen and (max-width: 1280px) {
  .proof-image {
    height: 770px;
  }
}

@media only screen and (max-width: 1024px) {
  .background {
    height: 1100px;
  }

  .proof-image {
    height: 670px;
  }
}

@media only screen and (max-width: 768px) {
  .background {
    height: 870px;
  }

  .proof-image {
    height: 500px;
  }
}

@media only screen and (max-width: 640px) {
  .background {
    height: 700px;
  }

  .proof-image {
    height: 330px;
  }
}




/* Hide the + icon when expanded, show - icon */
.accordion__item--expanded .icon-open {
  display: none;
}

.accordion__item--expanded .icon-close {
  display: inline;
}

/* Adjust the vertical line to start from the middle-bottom of the icon */
.accordion__item span {
  position: absolute;
  top: 40px;
  /* Adjust this value as needed */
  left: 26px;
}

.choose-image {
  background-image: url('../public/images/Mask\ group\ \(1\).png');
  background-repeat: no-repeat;
  background-position: center right;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 1024px) {
  .choose-image {
    background-position: center bottom;
    object-fit: contain;
  }
}

.cliet-image {
  background-image: url('../public/images/Group\ 1000002695.png');
  background-repeat: no-repeat;
  background-position: center;
  object-fit: cover;
}



/* CSS */
.slick-dots .slick-active div {
  background-color: #578cda !important;
  /* Active dot color */
  transform: scale(1.4);
  /* Make the active dot bigger */
}


.blurred-circle {
  width: 250px;
  height: 250px;
  background-color: #578CDA;
  /* Base color */
  border-radius: 50%;
  /* Makes it a circle */
  filter: blur(100px);
  /* Apply a blur effect */
  margin: 0 auto;
  /* Center the circle horizontally */
  opacity: 0.3;
}

.left_arrow_proof {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: #4283DE;
  color: #FFFFFF;
  border-radius: 50%;
  position: absolute;
  left: -10px;
  top: 43%;
  transform: translate(0, -50%);
  cursor: pointer;
  z-index: 1;
  font-size: 30px;
}
.right_arrow_proof{
  display: flex;
        align-items: center;
        justify-content:center;
        width: 50px;
        height: 50px;
        background-color: #4283DE;
        color: #FFFFFF;
        border-radius:50%;
        position: absolute;
        right: -40px;
        top: 43%;
        transform: translate(0, -50%);
        cursor: pointer;
        z-index: 1;
        font-size: 30px;
}

@media screen and (max-width:600px) {
  .left_arrow_proof {
    left: -35px;
  }
}

@media screen and (max-width:450px) {
  .left_arrow_proof {
    left: -15px;
    width: 35px;
    height: 35px;
    font-size: 20px;
  }
  .right_arrow_proof{
    width: 35px;
    height: 35px;
    font-size: 20px;
    right: -25px;
  }

}
.carousel-padding{
  padding-bottom: 50px;
}
.grad_one {
  background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 24.98%, #DEEAFF 100%);
  border: 1px solid #D9D9D9;
}

.grad_one:hover {
  background: #E9F1FF;
}

.grad_two {
  background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 24.98%, #EAFFF8 100%);
  border: 1px solid #D9D9D9;
}

.grad_two:hover {
  background: #E7F6F1;

}

.grad_three {
  background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 24.98%, #FFEFEF 100%);
  border: 1px solid #D9D9D9;
}

.grad_three:hover {
  background: #FFF0F0;

}

.grad_four {
  background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 24.98%, #FFFADE 100%);
  border: 1px solid #D9D9D9;
}

.grad_four:hover {
  background: #FDFBEA;
}

.styles-module_whatsappButton__tVits{
  bottom: 1rem !important;
    left: 1.5rem !important;
}
